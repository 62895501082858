
import {
  Component, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import type { FSXARichText } from 'fsxa-pattern-library'
import { RichTextElement } from 'fsxa-api'
import { isVimeoVideo, isYouTubeVideo } from '../../shared/general/services/VideoUrlService'
import IImage from '../../shared/general/interfaces/IImage'
import { ILink } from '../../shared/general/interfaces/ILink'
import IVideo from '../../shared/general/interfaces/IVideo'
import { openLightbox } from '../../shared/general/services/LightboxService'
import CarouselImage from './CarouselImage.vue'
import CarouselSlideContent from './CarouselSlideContent.vue'
import IImageVideoContent from '../../shared/general/interfaces/IImageVideoContent'

@Component({
  name: 'CarouselElement',
  components: {
    CarouselImage,
    CarouselSlideContent,
    FSXARichText: async () => (await import('fsxa-pattern-library')).FSXARichText,
  },
})
export default class CarouselElement extends Vue {
  @Prop({ default: () => [] }) links! : ILink[]

  @Prop({ default: () => [] }) headlines! : FSXARichText[]

  @Prop({ default: '' }) headline! : string

  @Prop({ default: '' }) text! : string

  @Prop({ required: true }) image! : IImage

  @Prop({ default: true }) lazy! : boolean

  @Prop({ required: true }) variant! : 'stage-large' | 'stage-medium' | 'carousel'

  @Prop({ required: true }) controlsGap! : boolean

  @Prop() checkmarks ?: RichTextElement[][]

  @Prop() video ?: IVideo

  @Prop({ default: '' }) wrapperSpacings! : string

  private showCookieLayer : boolean = false

  private videoFormat : 'youtube' | 'vimeo' | 'unknown' = 'unknown'

  private headlineSizes : Record<string, string> = {
    'stage-large': 'text-3xl sm:text-5xl lg:text-6xl',
    'stage-medium': 'text-3xl sm:text-4xl xl:text-5xl',
    carousel: 'text-xl sm:text-2xl',
  }

  private wrapperClasses : Record<string, string> = {
    'stage-large': 'mx-auto flex md:items-end flex-col md:flex-row max-h-[40rem] min-h-[62vh]',
    'stage-medium': 'mx-auto flex md:items-end flex-col md:flex-row min-h-[400px]',
    carousel: 'h-full flex md:items-end flex-col md:flex-row min-h-[240px] sm:min-h-[300px]',
  }

  private checkShowCookieLayer () : boolean {
    if (!process.client) return true

    if (this.video?.format === 'internal') return false

    const videoID = this.video?.id
    if (!videoID) return false

    const isYoutube = isYouTubeVideo(videoID)
    if (isYoutube) {
      this.videoFormat = 'youtube'
      return !this.youtubeCookiesAccepted
    }

    const isVimeo = isVimeoVideo(videoID)
    if (isVimeo) {
      this.videoFormat = 'vimeo'
      return !this.vimeoCookiesAccepted
    }

    return true
  }

  private showLightbox () : void {
    const content = {
      tag: ['youtube', 'vimeo', 'external'].includes(this.video?.format || '') ? 'BaseVideo' : 'BaseHtmlPlayer',
      props: {
        id: this.video?.id,
        mediaType: 'video',
        image: this.image,
        htmlPlayerElements: this.video?.htmlPlayerElements,
        privacyHash: this.video?.privacyHash,
      },
    } as IImageVideoContent

    openLightbox(content)
  }

  private get youtubeCookiesAccepted () : boolean {
    return !!this.$store.state.PrivacySettings.settings.youtube
  }

  private get vimeoCookiesAccepted () : boolean {
    return !!this.$store.state.PrivacySettings.settings.vimeo
  }

  @Watch('$store.state.PrivacySettings.settings.youtube')
  @Watch('$store.state.PrivacySettings.settings.vimeo')
  private onVimeoConsentChange () {
    this.showCookieLayer = this.checkShowCookieLayer()
  }

  mounted () {
    this.showCookieLayer = this.checkShowCookieLayer()
  }
}
